import axios from 'axios'
import { ItemResponse } from '@/dto/response/ItemResponse'
import { FileServer } from '@/dto/response/FileServer'
import { firebaseUserID } from '@/services/Authentication'
import { remoteConfig, ConfigValue } from '@/services/RemoteConfig'
import { Item } from '@/dto/Item'
import { ItemComment } from '@/dto/ItemComment'

const serverUrl = remoteConfig.getValue(ConfigValue.appConfigHost).asString()
console.log('Using ' + serverUrl + ' as Host')

const fileServers: FileServer[] = []
const prefetchedItems: Item[] = []
const prefetchedComments: ItemComment[] = []

let language = 'de'
const defaultHeader = {
  'Content-Type': 'application/json',
  versionName: '0.1',
  versionCode: '0',
  os: 'ios'
}

export async function fetchItem(id: number): Promise<ItemResponse> {
  return new Promise(function (resolve, reject) {
    const prefetched = prefetchedItems.filter(pre => pre.id === id)
    if (prefetched.length > 0) {
      resolve(new ItemResponse(prefetched, prefetchedComments.filter(pre => pre.itemId === id)))
    }

    const authHeader = {
      firebaseAuthToken: firebaseUserID
    }

    const langHeader = {
      hl: language,
      cc: language.toUpperCase()
    }

    axios.get(serverUrl + '/items/' + id, { headers: { ...defaultHeader, ...authHeader, ...langHeader } })
      .then(function (response) {
        addFileServers(response.headers['file-server'])
        const itemResponse = ItemResponse.fromDTO(response.data)

        if (!itemResponse) {
          console.log(response)
          reject(new Error('No valid ItemResponse received'))
          return
        }

        for (const i of itemResponse.items) {
          cacheItem(i)
        }

        for (const c of itemResponse.comments) {
          cacheComment(c)
        }

        resolve(itemResponse)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function fetchFeaturedItems(): Promise<ItemResponse> {
  return fetchItems(serverUrl + '/items/featured?offset=0&amount=30')
}

export async function fetchReleasedItems(): Promise<ItemResponse> {
  return fetchItems(serverUrl + '/items?offset=0&amount=30')
}

export async function fetchAdminItems(): Promise<ItemResponse> {
  return fetchItems(serverUrl + '/users/1/items?offset=0&amount=30')
}

async function fetchItems(fromURL: string): Promise<ItemResponse> {
  return new Promise(function (resolve, reject) {
    // console.log('Fetch frontpage...')
    const authHeader = {
      firebaseAuthToken: firebaseUserID
    }

    const langHeader = {
      hl: language,
      cc: language.toUpperCase()
    }

    axios.get(fromURL, { headers: { ...defaultHeader, ...authHeader, ...langHeader } })
      .then(function (response) {
        addFileServers(response.headers['file-server'])
        const itemResponse = ItemResponse.fromDTO(response.data)

        if (!itemResponse) {
          console.log(response)
          reject(new Error('No valid ItemResponse received'))
          return
        }

        for (const i of itemResponse.items) {
          cacheItem(i)
        }

        for (const c of itemResponse.comments) {
          cacheComment(c)
        }

        // console.log(itemResponse)
        resolve(itemResponse)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

function cacheComment(c: ItemComment) {
  if (prefetchedComments.filter(pre => pre.id === c.id).length === 0) {
    prefetchedComments.push(c)
  }
}

function cacheItem(i: Item) {
  if (prefetchedItems.filter(pre => pre.id === i.id).length === 0) {
    prefetchedItems.push(i)
  }
}

function addFileServers(fromHeaderValue: string) {
  if (!fromHeaderValue || fromHeaderValue.length === 0) {
    return
  }

  const servers = JSON.parse(fromHeaderValue) // as Array<FileServer>

  servers.forEach((server: FileServer) => {
    if (server.url && server.url.length > 0) {
      // Server URL is valid
      if (fileServers.filter(serv => serv.url === server.url).length === 0) {
        // Server URL is not in fileServers yet
        fileServers.push(server)
        console.log('New Fileserver ' + server.url + ' added.')
      }
    }
  })
}

export function randomFileServer(): string | undefined {
  if (fileServers.length === 0) {
    return
  }

  return fileServers[Math.floor(Math.random() * fileServers.length)].url
}

export function setLanguage(to: string) {
  if (to.length > 0) {
    language = to
    console.log('Update language server to: ' + language)
  }
}
